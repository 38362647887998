var textIndex = 0;
var words = ['site', 'blog', 'network', 'app', 'influence'];

function change_text() {
    var text = words[textIndex];
    // $("#change-word").fadeIn("slow");
    // $("#change-word").fadeIn("2000").text(text);
    $('#change-word').animate({ opacity: 0 }, function () {
        $(this).text(text).animate({
            opacity: 1
        });
    })
    textIndex++;
    if (textIndex >= words.length) {
        textIndex = 0;
    }
}

jQuery(function () {
    $(document).on('click', function (e) {
        if (!($(e.target).children().is('.icon-bar') || $(e.target).is('.navbar-toggle'))) {
            if ($('.navbar-collapse').hasClass('in')) {
                $('.navbar-collapse').collapse('hide');
            }
        }
    });

    // $('select').niceSelect();
    // $('select').on('change', function () {
    //     this.niceSelect('update');
    // });
    // $('select').niceSelect('update');
    // if ($('#change-word').length > 0) {
    var myVar = setInterval(function () { change_text() }, 2000);
    // }
});
window.onerror = function (message, url, lineNo, colNo, error) {

    console.log(arguments);
 
    let container = document.createElement('div');
 
    container.style.color = 'red';
    container.style.position = 'fixed';
    container.style.background = '#eee';
    container.style.padding = '2em';
    container.style.top = '1em';
    container.style.left = '1em';
 
    let msg = document.createElement('pre');
    msg.innerText = [
       'Message: ' + message,
       'URL: ' + url,
       'Line: ' + lineNo,
       'Column: ' + colNo,
       'Stack: ' + (error && error.stack)
    ].join('\n');
 
    container.appendChild(msg);
 
    // document.body.appendChild(container);
 };

var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = true;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function () {
        that.tick();
    }, delta);
};
/*
window.onload = function () {
    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};*/